import './App.css';
import WelcomePage from './pages/welcome-page';
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import PrivacyPolicy from './pages/privacy-policy';
import TermsOfUse from './pages/termsofuse';

function App() {
  return (
    
    <BrowserRouter>
    <Routes>
      
      <Route exact path="" element={<WelcomePage />}/>
      <Route path="Privacy-Policy" element={<PrivacyPolicy />} />
      <Route path="TermsOfUse" element={<TermsOfUse />} />
     
      
    </Routes>
  </BrowserRouter>
  
  );
}

export default App;
